import React, {useRef} from "react";
import WaveFunction from "./WaveFunction";
import Box from "@mui/material/Box";
import {useState, useEffect} from "react";
import {CircularProgress, Grid, Input, LinearProgress, Slider} from "@mui/material";
import Typography from "@mui/material/Typography";

import {
  Col,
  Row,
  Button, Progress,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import {useDispatch, useSelector} from "react-redux";
import GetFetchData from "../../components/Request/Request";
import {CheckFetchResult, GetFetchProject, GetFetchProjectDetail} from "../../actions/getFetchAction";
import PatchFetchData from "../../components/Request/PatchFetchData";

const GetProjectDetail = (props) => {
  const dispatch = props.dispatch;
  const projectSelector = props.projectSelector;
  // console.log("projectSelector: ", projectSelector)
  const audioSelector = props.audioSelector;
  const pageIdx = props.pageIdx;
  const positionChanged = props.positionChanged;
  const lastPosition = projectSelector.lastPositionData;

  const page = 1;
  const pageNumber = 1;
  const project = projectSelector.selectedProject;
  const pageSum = Number(page) + Number(pageIdx);
  if (positionChanged) {
	const fetchArgs = {
	  fetchUrl: "/project/page/audio?page=" + pageSum + "&page_number=" + pageSum + "&project=" + project,
	  fetchMethod: "GET",
	  fetchHeader: {
		Authorization: "Token " + localStorage.getItem("token")
	  },
	  fetchAction: GetFetchProjectDetail,
	  fetchDispatch: dispatch
	};
	GetFetchData(fetchArgs);
  } else {
	const fetchArgs = {
	  fetchUrl: "/project/page/audio?page=" + lastPosition + "&page_number=" + lastPosition + "&project=" + project,
	  fetchMethod: "GET",
	  fetchHeader: {
		Authorization: "Token " + localStorage.getItem("token")
	  },
	  fetchAction: GetFetchProjectDetail,
	  fetchDispatch: dispatch
	};
	// console.log("fetchArgs: ", fetchArgs)
	GetFetchData(fetchArgs);
  }

}

const PatchProjectDetail = (props) => {
  const dispatch = props.dispatch;
  const transcript = props.transcript;
  console.log("transcript: ", transcript)
  const projectData = props.projectSelector.projectData;
  const projectDetailData = props.projectSelector.projectDetailData.data.results[0];
  const timeStamp = props.timeStamp;
  console.log("timeStamp: ", timeStamp)
  const modifiedTranscript = {
	"id": projectDetailData.id,
	"project": projectDetailData.project,
	"file_name": projectDetailData.file_name,
	"new_start_time": Math.floor(timeStamp[0].start * 1000),
	"new_end_time": Math.floor(timeStamp[0].end * 1000),
	"new_sentence": transcript[0].text,
	"new_meaning_sentences": transcript[0].meaning_text,
	"is_adult": false
  }
  if (!timeStamp[0].start) {
	modifiedTranscript.new_start_time = projectDetailData.new_start_time
  }
  if (!timeStamp[0].end) {
	modifiedTranscript.new_end_time = projectDetailData.new_end_time
  }
  if (!transcript[0].text) {
	modifiedTranscript.new_sentence = projectDetailData.new_sentences
  }
  if (!transcript[0].meaning_text) {
	modifiedTranscript.new_meaning_sentences = projectDetailData.new_meaning_sentences
  }
  if (typeof (transcript[0].exclude) === 'undefined') {
	modifiedTranscript.is_adult = String(projectDetailData.is_adult)
  } else if (transcript[0].exclude === true) {
	console.log("excluded: ", transcript[0].exclude)
	modifiedTranscript.is_adult = 'true'
  } else {
	modifiedTranscript.is_adult = 'false'
  }
  const fetchArgs = {
	fetchUrl: "/audio",
	fetchMethod: "PATCH",
	fetchHeader: {
	  "Content-Type": "application/json",
	  Authorization: "Token " + localStorage.getItem("token")
	},
	fetchBody: JSON.stringify(modifiedTranscript),
	fetchAction: CheckFetchResult,
	fetchDispatch: dispatch,
	fetchErrorAction: CheckFetchResult
  };

  console.log(modifiedTranscript)
  PatchFetchData(fetchArgs);
}


function Wave() {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const projectSelector = useSelector(state => state.getFetchReducer);
  const audioSelector = useSelector(state => state.getFetchReducer);

  const [transcript, setTranscript] = useState([
	{
	  audio_id: null,
	  text: null,
	  meaning_text: null,
	  start: null,
	  end: null,
	  exclude: null,
	}
  ]);
  const [timeStamp, setTimeStamp] = useState([
	{
	  audio_id: null,
	  start: null,
	  end: null,
	}
  ]);
  useEffect(() => {
	console.log("transcript: ", transcript[0])
  }, [transcript]);
  useEffect(() => {
	// console.log("timeStamp: ", timeStamp[0])
  }, [timeStamp]);
  const [pageIdx, setPageIdx] = useState(0);
  const [audioData, setAudioData] = useState([]);
  const [defaultAudioId, setDefaultAudioId] = useState(0);
  const [audio, setAudio] = useState(new Audio());
  const [positionChanged, setPositionChanged] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [audioVolume, setAudioVolume] = useState(1);
  let maxIdx = 0;
  useEffect(() => {
	// console.log("pageidx: ", pageIdx)
	GetProjectDetail({dispatch, projectSelector, audioSelector, pageIdx, positionChanged});
  }, [pageIdx]);

  useEffect(() => {
	if (!positionChanged) {
	  setPageIdx(Number(projectSelector.lastPositionData) - 1)
	}
  }, []);

  useEffect(() => {
	if (isMounted.current) {
	  if (audioSelector.projectDetailData.data.results) {
		const projectDetail = audioSelector.projectDetailData.data.results;
		const next = audioSelector.projectDetailData.data.next;
		const previous = audioSelector.projectDetailData.data.previous;
		const audio_file = projectDetail[0].audio;
		const start = projectDetail[0].new_start_time;
		const end = projectDetail[0].new_end_time;
		const text = projectDetail[0].new_sentences;
		const meaning_text = projectDetail[0].new_meaning_sentences;
		const originalText = projectDetail[0].sentences;
		const exclude = projectDetail[0].is_adult;
		maxIdx = audioSelector.projectDetailData.data.count;
		setDefaultAudioId(projectDetail[0].id)
		// const sound = new File([serverURL + projectDetail[0].audio_file], "recAudio.mp3", {lastModified: new Date().getTime(), type: "audio"});
		setAudio(projectDetail[0].audio_file);

		const audioJson = [
		  {
			"next": next,
			"previous": previous,
			"audio_id": audioSelector.projectDetailData.data.results[0].id,
			"file_name": audioSelector.projectDetailData.data.results[0].file_name,
			"audio": audio_file,
			"start": start / 1000,
			"end": end / 1000,
			"text": text,
			"meaning_text": meaning_text,
			"originalText": originalText,
			"exclude": exclude
		  },
		]
		setAudioData(audioJson);
		setIsLoaded(true);
	  }
	} else {
	  isMounted.current = true;
	}
  }, [audioSelector.projectDetailData]);

  const updateTranscript = (data) => {
	setTranscript(
	  [{
		...transcript[0],
		audio_id: defaultAudioId,
		text: data.text,
	  }]
	)
  }

  const updateMeaningTranscript = (data) => {
	setTranscript(
	  [{
		...transcript[0],
		audio_id: defaultAudioId,
		meaning_text: data.meaning_text,
	  }]
	)
  }

  const updateTime = (data) => {
	setTimeStamp(
	  [{
		...transcript[0],
		audio_id: defaultAudioId,
		start: data.start,
		end: data.end,
	  }]
	)
  }

  const updateExclude = (data) => {
	setTranscript(
	  [{
		...transcript[0],
		audio_id: defaultAudioId,
		exclude: data,
	  }]
	)
  }

  const updateNowAudio = (data) => {
	setIsLoaded(false);
	PatchProjectDetail({dispatch, transcript, projectSelector, timeStamp});
	if (data === 'next') {
	  if (maxIdx !== pageIdx + 2) {
		if (!positionChanged) {
		  setPositionChanged(true)
		}
		setPageIdx(pageIdx + 1);
	  } else {
		alert("마지막 페이지입니다.")
		setPageIdx(0);
	  }

	  setIsLoaded(true);

	} else if (data === 'prev') {
	  if (pageIdx !== 0) {
		if (!positionChanged) {
		  setPageIdx(Number(projectSelector.lastPositionData))
		  setPositionChanged(true)
		}
		setPageIdx(pageIdx - 1);
	  }

	  setIsLoaded(true);

	}

	setTranscript(
	  [
		{
		  audio_id: null,
		  text: null,
		  meaning_text: null,
		  start: null,
		  end: null,
		  exclude: null,
		}
	  ]
	)
	setTimeStamp(
	  [
		{
		  audio_id: null,
		  start: null,
		  end: null,
		}
	  ]
	)
  }

  const changeVolume = (value) => {
	setAudioVolume(value);
  }
  const handleNowAudioChange = (data) => {
	updateNowAudio(data);
  }
  const handleTranscriptChange = (data) => {
	updateTranscript(data);
  }
  const handleMeaningTranscriptChange = (data) => {
	updateMeaningTranscript(data);
  }
  const handleTimeChange = (data) => {
	updateTime(data);
  }
  const handleExcludeChange = (data) => {
	updateExclude(data);
  }
  const handleInputChange = (event) => {
	PatchProjectDetail({dispatch, transcript, projectSelector, timeStamp});
	setPositionChanged(true)
	if (event.target.value > maxIdx - 2) {
	  setPageIdx(maxIdx - 2);
	} else {
	  setPageIdx(event.target.value === '' ? 0 : Number(event.target.value));
	}
  };
  const handleVolumeChange = (event) => {
	setAudioVolume(event);
  }

  try {
	maxIdx = audioSelector.projectDetailData.data.count;
  } catch (e) {
	console.log("error: ", e);
  }


  if (audioData) {
  } else {
	return (
	  <Grid
		container
		spacing={0}
		direction="column"
		alignItems="center"
		justifyContent="center"
		sx={{minHeight: '60vh'}}
	  >
		<Grid item xs={3}>
		  <CircularProgress/>
		</Grid>
	  </Grid>
	)
  }
  if (audioData.length === 0) {
	return (
	  <Grid
		container
		spacing={0}
		direction="column"
		alignItems="center"
		justifyContent="center"
		sx={{minHeight: '60vh'}}
	  >
		<Grid item xs={3}>
		  <CircularProgress/>
		</Grid>
	  </Grid>
	)
  } else {
	return (
	  <div>
		<Row>
		  <Col span={24}>
			<Widget className="mb-4">
			  <Box sx={{display: 'flex', alignItems: 'center'}}>
				<Box sx={{width: '100%', mr: 1}}>
				  <LinearProgress variant="determinate" value={
					positionChanged ?
					  100 * Number(pageIdx) / (maxIdx - 2)
					  :
					  100 * Number(projectSelector.lastPositionData) / (maxIdx - 2)
				  }/>
				</Box>
				<Box sx={{maxWidth: 50}}>
				  <Input
					value={pageIdx}
					size="small"
					onChange={handleInputChange}
					// onBlur={handleBlur}
					inputProps={{
					  step: 10,
					  min: 0,
					  max: maxIdx - 2,
					  type: 'number',
					  'aria-labelledby': 'input-slider',
					}}
				  />
				</Box>
				<Box sx={{minWidth: 35}}>
				  <Typography variant="body2" color="textSecondary">
					/ {maxIdx - 2}
				  </Typography>
				</Box>
			  </Box>
			  {
				isLoaded ?
				  <WaveFunction jsonItem={audioData[0]} audio_id={audioData[0].audio_id}
								originalItem={audioData[0]} transcriptValue={transcript}
								onTranscriptChange={handleTranscriptChange}
								onMeaningTranscriptChange={handleMeaningTranscriptChange}
								onExcludeChange={handleExcludeChange}
								onTimeChange={handleTimeChange}
								onNowAudioChange={handleNowAudioChange}
								audio={audio}
								audioVolume={audioVolume}
								onVolumeChange={handleVolumeChange}
				  />
				  :
				  <Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					sx={{minHeight: '53vh'}}
				  >
					<Grid item xs={3}>
					  <CircularProgress/>
					</Grid>
				  </Grid>

			  }
			</Widget>
		  </Col>
		</Row>
	  </div>
	);
  }
}

export default Wave;
