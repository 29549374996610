const GetFetchData = (fetchArgs) => {
	// console.log(fetchArgs)
	const {fetchUrl, fetchMethod, fetchHeader, fetchAction, fetchDispatch, fetchErrorAction} = fetchArgs;
	const fetchData = fetch("/api" + fetchUrl, {
		method: fetchMethod,
		headers: {
			Authorization: fetchHeader.Authorization
		}
	})
	  .then(response => {
		  // console.log(response)
		  if (!response.ok) {
			  if (response.status === 401) {
				  // alert("로그인이 필요합니다.")
			  }
			  throw new Error(response.statusText)
		  }
		  return response.json()
	  }).catch(err => {
		  fetchDispatch(fetchErrorAction("failed"))
		  // console.log(err)
	  }).then(data => {
		  // console.log(data)
		  fetchDispatch(fetchAction(data))
	  })
}

export default GetFetchData;
