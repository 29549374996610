// -- React and related libs
import React from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";

// -- Third Party Libs
import PropTypes from "prop-types";

// -- Custom Components
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import Breadcrumbs from "../Breadbrumbs/Breadcrumbs";
import Dashboard from "../../pages/dashboard/Dashboard";
import Typography from "../../pages/typography/Typography";
import Notifications from "../../pages/notifications/Notifications";
import Tables from "../../pages/tables/Tables";
import Charts from "../../pages/uielements/charts/Charts";
import Icons from "../../pages/uielements/icons/IconsPage";
import Maps from "../../pages/uielements/maps/google/GoogleMapPage";

import Wave from "../../pages/wave/Wave";
// import Manage from "../../pages/manage/manage";

// -- Component Styles
import s from "./Layout.module.scss";
import Project from "../../pages/project/Project";
import Manage from "../../pages/manage/manage";
import Download from "../../pages/download/download";
import TextCheck from "../../pages/textCheck/TextCheck";

const Layout = (props) => {
  return (
    <div className={s.root}>
      <div className={s.wrap}>
        <Header />
        <Sidebar />
        <main className={s.content}>
          <Breadcrumbs url={props.location.pathname} />
          <Switch>
            <Route path="/wave" exact render={() => <Redirect to="wave/dashboard"/>} />
            <Route path="/wave/dashboard" exact component={Dashboard}/>
            <Route path="/wave/typography" exact component={Typography} />
            <Route path="/wave/tables" exact component={Tables} />
            <Route path="/wave/notifications" exact component={Notifications} />
            <Route path="/wave/ui-elements" exact render={() => <Redirect to={"/wave/ui-elements/charts"} />} />
            <Route path="/wave/ui-elements/charts" exact component={Charts} />
            <Route path="/wave/ui-elements/icons" exact component={Icons} />
            <Route path="/wave/ui-elements/maps" exact component={Maps} />
            <Route path="/wave/waveform" exact component={Wave} />
            <Route path="/wave/project" exact component={Project} />
            <Route path="/wave/check-text" exact component={Project} />
            <Route path="/wave/check-text-detail" exact component={TextCheck} />
            <Route path="/wave/project-assign" exact component={Manage} />
            <Route path="/wave/project-download" exact component={Download} />
            {/*<Route path="/wave/manage" exact component={Manage} />*/}
            <Route path='*' exact render={() => <Redirect to="/error" />} />
          </Switch>
        </main>
        <Footer />
      </div>
    </div>
  );
}

Layout.propTypes = {
  sidebarOpened: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(store) {
  return {
    sidebarOpened: store.navigation.sidebarOpened,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
