import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge, Button,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "../tables/Tables.module.scss";
import mock from "../tables/mock.js";
import {
  CheckFetchResult,
  GetFetchProject,
  GetFetchProjectDetail,
  GetFetchProjectList,
  GetFetchUser
} from "../../actions/getFetchAction";
import GetFetchData from "../../components/Request/Request";
import {useDispatch, useSelector} from "react-redux";
import PostFetchData from "../../components/Request/Post";


const Download = () => {
  const SERVER_URL = "https://dev-vst.vision21tech.com/api"
  // const SERVER_URL = "http://localhost:8000/api"
  const CSV_URL = "http://localhost:8000/api"
  const audioSelector = useSelector(state => state.getFetchReducer);
  const [secondTable] = useState(mock.secondTable);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const isMounted = React.useRef(false);

  const pageSize = 10;
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setSecondTablePage = (e, index) => {
	e.preventDefault();
	setSecondTableCurrentPage(index);
  }

  const tableMenuOpen = () => {
	setTableMenuOpen(!tableDropdownOpen);
  }

  const projectSelector = useSelector(state => state.getFetchReducer);
  const dispatch = useDispatch();
  const fetchProjectArgs = {
	fetchUrl: "/project/list/all",
	fetchMethod: "GET",
	fetchHeader: {
	  Authorization: "Token " + localStorage.getItem("token")
	},
	fetchAction: GetFetchProjectList,
	fetchDispatch: dispatch
  };

  const handleSelect = (projeceName) => {
	window.open(SERVER_URL + "/project/download/csv?project_name=" + projeceName)
	window.open(SERVER_URL + "/project/download/audio?project_name=" + projeceName)
  }

  useEffect(() => {
	GetFetchData(fetchProjectArgs);
	// GetFetchData(fetchUserArgs);
  }, []);

  if (projectSelector.projectListData && projectSelector.userData) {
	return (
	  <div>
		<Row>
		  <Col>
			<Row className="mb-4">
			  <Col>
				<Widget>
				  <div className={s.tableTitle}>
					<div className="headline-2">Project Download</div>
				  </div>
				  <div className="widget-table-overflow">
					<Table className="table-striped table-borderless table-hover" responsive>
					  <thead>
					  <tr>
						<th>
						  <div className="checkbox checkbox-primary">
						  </div>
						</th>
						<th className="col-md-3">프로젝트명</th>
						<th className="col-md-2">프로젝트 ID</th>
						<th className="col-md-2">작업파일 수</th>
						<th className="col-md-2">배정된 작업자</th>
						<th className="col-md-3">프로젝트 다운로드</th>
					  </tr>
					  </thead>
					  <tbody>
					  {projectSelector.projectListData.message
						.map(item => (
						  <tr key={uuidv4()}>
							<td>
							  <div className="checkbox checkbox-primary">
							  </div>
							</td>
							<td>{item.project_name}</td>
							<td>{item.id}</td>
							<td>{item.file_count}</td>
							<td>{item.assignee}</td>
							<td>
							  <Button onClick={() => handleSelect(item.project_name)}>
								프로젝트 다운로드
							  </Button>
							</td>
						  </tr>
						))}
					  </tbody>
					</Table>
				  </div>
				</Widget>
			  </Col>
			</Row>
		  </Col>
		</Row>
	  </div>
	)
  } else {
	return (
	  <div>loading</div>
	)
  }

}

export default Download;
