const PostFetchData = (fetchArgs) => {
	console.log(fetchArgs)
	const {fetchUrl, fetchMethod, fetchHeader, fetchBody, fetchAction, fetchDispatch} = fetchArgs;
	const fetchData = fetch("/api" + fetchUrl, {
		method: fetchMethod,
		headers: fetchHeader,
		body: fetchBody
	})
	  .then(response => {
		  if (!response.ok) {
			  console.log(response)
			  console.log("fetchAction({result: 'fail'})")
			  fetchDispatch(fetchAction({result: "fail"}))
			  throw new Error(response.statusText)
		  }
		  return response.json()
	  }).catch(err => {
		  console.log(err)
	  }).then(data => {
		  console.log(data)
		  if (!data) {
			  return
		  }
		  fetchDispatch(fetchAction(data))
	  })
}

export default PostFetchData;
