import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'reactstrap';
import {withRouter} from 'react-router-dom';
import s from "./Sidebar.module.scss";
import LinksGroup from "./LinksGroup/LinksGroup.js";
import {changeActiveSidebarItem} from "../../actions/navigation.js";
import SofiaLogo from "../Icons/SofiaLogo.js";
import cn from "classnames";

const Sidebar = (props) => {
	const {
		activeItem = '',
		...restProps
	} = props;

	const [burgerSidebarOpen, setBurgerSidebarOpen] = useState(false)

	useEffect(() => {
		if (props.sidebarOpened) {
			setBurgerSidebarOpen(true)
		} else {
			setTimeout(() => {
				setBurgerSidebarOpen(false)
			}, 0);
		}
	}, [props.sidebarOpened])

	return (
	  <nav className={cn(s.root, {[s.sidebarOpen]: burgerSidebarOpen})}>
		  <header className={s.logo}>
			  {/*<SofiaLogo/>*/}
			  {/*<span className={s.title}>SOFIA</span>*/}
		  </header>
		  <ul className={s.nav}>
			  {/*<LinksGroup*/}
			  {/*onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}*/}
			  {/*activeItem={props.activeItem}*/}
			  {/*header="Dashboard"*/}
			  {/*isHeader*/}
			  {/*iconName={<i className={'eva eva-home-outline'}/>}*/}
			  {/*link="/wave/dashboard"*/}
			  {/*index="dashboard"*/}
			  {/*badge="9"*/}
			  {/*/>*/}
			  <h5 className={s.navTitle}>WAVE</h5>
			  <LinksGroup
				onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
				activeItem={props.activeItem}
				header="Project"
				isHeader
				iconName={<i className={'eva eva-bar-chart-outline'}/>}
				link="/wave/project"
				index="Project"
			  />
			  <h5 className={s.navTitle}>MANAGE</h5>
			  <LinksGroup
				onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
				activeItem={props.activeItem}
				header="Project Assign"
				isHeader
				iconName={<i className={'eva eva-file-add-outline'}/>}
				link="/wave/project-assign"
				index="project-assign"
			  />
			  <LinksGroup
				onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
				activeItem={props.activeItem}
				header="Project Download"
				isHeader
				iconName={<i className={'eva eva-download-outline'}/>}
				link="/wave/project-download"
				index="project-download"
			  />
			  <LinksGroup
				onActiveSidebarItemChange={activeItem => props.dispatch(changeActiveSidebarItem(activeItem))}
				activeItem={props.activeItem}
				header="Check Text"
				isHeader
				iconName={<i className={'eva eva-checkmark-outline'}/>}
				link="/wave/check-text"
				index="check-text"
			  />
		  </ul>
		  {/*<div className="bg-widget d-flex mt-auto ml-1">*/}
		  {/*  <Button className="rounded-pill my-3 body-2 d-none d-md-block" type="submit" color="secondary-red">Unlock Full Version</Button>*/}
		  {/*</div>*/}
	  </nav>
	);
}

Sidebar.propTypes = {
	sidebarOpened: PropTypes.bool,
	dispatch: PropTypes.func.isRequired,
	activeItem: PropTypes.string,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
}

function mapStateToProps(store) {
	return {
		sidebarOpened: store.navigation.sidebarOpened,
		activeItem: store.navigation.activeItem,
	};
}

export default withRouter(connect(mapStateToProps)(Sidebar));
