import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge, Button,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "../tables/Tables.module.scss";
import mock from "../tables/mock.js";
import {
  CheckFetchResult,
  GetFetchProject,
  GetFetchProjectDetail,
  GetFetchProjectList,
  GetFetchUser
} from "../../actions/getFetchAction";
import GetFetchData from "../../components/Request/Request";
import {useDispatch, useSelector} from "react-redux";
import PostFetchData from "../../components/Request/Post";


const Manage = () => {
  const audioSelector = useSelector(state => state.getFetchReducer);
  const [secondTable] = useState(mock.secondTable);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const isMounted = React.useRef(false);

  const pageSize = 10;
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setSecondTablePage = (e, index) => {
	e.preventDefault();
	setSecondTableCurrentPage(index);
  }

  const tableMenuOpen = () => {
	setTableMenuOpen(!tableDropdownOpen);
  }

  const projectSelector = useSelector(state => state.getFetchReducer);
  const dispatch = useDispatch();
  const fetchProjectArgs = {
	fetchUrl: "/project/list/all",
	fetchMethod: "GET",
	fetchHeader: {
	  Authorization: "Token " + localStorage.getItem("token")
	},
	fetchAction: GetFetchProjectList,
	fetchDispatch: dispatch
  };

  const fetchUserArgs = {
	fetchUrl: "/auth/users",
	fetchMethod: "GET",
	fetchHeader: {
	  Authorization: "Token " + localStorage.getItem("token")
	},
	fetchAction: GetFetchUser,
	fetchDispatch: dispatch
  };

  const handleSelect = (e) => {
	const jsonResult = JSON.parse(e.target.value);
	if (jsonResult.username === null) {
	  return;
	}
	const fetchPostArgs = {
	  fetchUrl: "/project/assign",
	  fetchMethod: "POST",
	  fetchHeader: {
		"Content-Type": "application/json",
		Authorization: "Token " + localStorage.getItem("token")
	  },
	  fetchBody: e.target.value,
	  fetchAction: CheckFetchResult,
	  fetchDispatch: dispatch
	};
	PostFetchData(fetchPostArgs);
  }
  useEffect(() => {
	if (isMounted.current) {
	  if (projectSelector.checkFetchResultData.result === "success") {
		alert("작업자가 배정되었습니다.")
		GetFetchData(fetchProjectArgs);
	  } else if (projectSelector.checkFetchResultData.result === "fail") {
		alert("작업자 배정에 실패했습니다")
	  }
	} else {
	  isMounted.current = true;
	}

  }, [projectSelector.checkFetchResultData]);

  useEffect(() => {
	GetFetchData(fetchProjectArgs);
	GetFetchData(fetchUserArgs);
  }, []);
  if (projectSelector.projectListData && projectSelector.userData) {
	return (
	  <div>
		<Row>
		  <Col>
			<Row className="mb-4">
			  <Col>
				<Widget>
				  <div className={s.tableTitle}>
					<div className="headline-2">Project Assign</div>
				  </div>
				  <div className="widget-table-overflow">
					<Table className="table-striped table-borderless table-hover" responsive>
					  <thead>
					  <tr>
						<th>
						  <div className="checkbox checkbox-primary">
						  </div>
						</th>
						<th className="col-md-3">프로젝트명</th>
						<th className="col-md-2">프로젝트 ID</th>
						<th className="col-md-2">작업파일 수</th>
						<th className="col-md-2">배정된 작업자</th>
						<th className="col-md-3">작업자 선택</th>
					  </tr>
					  </thead>
					  <tbody>
					  {projectSelector.projectListData.message
						.map(item => (
						  <tr key={uuidv4()}>
							<td>
							  <div className="checkbox checkbox-primary">
							  </div>
							</td>
							<td>{item.project_name}</td>
							<td>{item.id}</td>
							<td>{item.file_count}</td>
							<td>{item.assignee}</td>
							<td>
							  <select className="form-control" onChange={handleSelect}>
								<option key={uuidv4()} disabled={false}
										value={JSON.stringify({
										  "username": null,
										  "project_name": null,
										})}>
								  작업자를 선택 해 주세요
								</option>
								{projectSelector.userData.data
								  .map(user => (
									<option key={uuidv4()} value={JSON.stringify({
									  "username": user.username,
									  "project_name": item.project_name,
									})}>{user.username}</option>
								  ))}
								}
							  </select>
							</td>
						  </tr>
						))}
					  </tbody>
					</Table>
				  </div>
				</Widget>
			  </Col>
			</Row>
		  </Col>
		</Row>
	  </div>
	)
  } else {
	return (
	  <div>loading</div>
	)
  }

}

export default Manage;
