import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withRouter, Redirect, Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {
	Container,
	Row,
	Col,
	Button,
	FormGroup,
	FormText,
	Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";
import Footer from "../../components/Footer/Footer.js";

import loginImage from "../../assets/registerImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import GoogleIcon from "../../components/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../components/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../../components/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../../components/Icons/AuthIcons/LinkedinIcon.js";
import {registerUser} from "../../actions/register.js";
import hasToken from "../../services/authService";
import Typography from "@mui/material/Typography";
import {CheckFetchResult} from "../../actions/getFetchAction";
import PostFetchData from "../../components/Request/Post";
import GetFetchData from "../../components/Request/Request";


const Register = (props) => {
	const dispatch = useDispatch();
	const isMounted = React.useRef(false);
	const projectSelector = useSelector(state => state.getFetchReducer);
	const [state, setState] = useState({familyName: '', firstName: '', email: '', username: '', password: ''})
	const [enableRegister, setEnableRegister] = useState(false);

	useEffect(() => {
		if (isMounted.current) {
			if (projectSelector.checkFetchResultData.result === "success") {
				alert("아이디를 사용할수 있습니다")
				setEnableRegister(true);
			} else if (projectSelector.checkFetchResultData.result === "fail") {
				alert("이미 가입된 아이디입니다")
				setEnableRegister(false);
			}
		} else {
			isMounted.current = true;
		}
	}, [projectSelector.checkFetchResultData]);

	const changeCred = (event) => {
		setState({...state, [event.target.name]: event.target.value})
	}

	const doRegister = (event) => {
		event.preventDefault();
		if (enableRegister === false) {
			alert("아이디 중복확인을 해주세요")
			return;
		}
		const overlapCheckFatchArgs = {
			fetchUrl: "/auth/signup",
			fetchMethod: "POST",
			fetchHeader: {
				"Content-Type": "application/json",
			},
			fetchBody: JSON.stringify({
				username: state.username,
				password: state.password,
				email: state.email,
				first_name: state.firstName,
				last_name: state.familyName
			}),
			fetchAction: CheckFetchResult,
			fetchDispatch: dispatch
		}
		PostFetchData(overlapCheckFatchArgs);
		alert("회원 가입이 완료 되었습니다")
		props.history.push("/login");
	}

	const overlapCheck = () => {
		if (state.username.length < 1) {
			alert("아이디를 입력해주세요")
			return;
		}
		const overlapCheckFatchArgs = {
			fetchUrl: "/auth/id",
			fetchMethod: "POST",
			fetchHeader: {
				"Content-Type": "application/json",
			},
			fetchBody: JSON.stringify({username: state.username}),
			fetchAction: CheckFetchResult,
			fetchDispatch: dispatch
		}
		PostFetchData(overlapCheckFatchArgs);
	}


	// const {from} = props.location.state || {from: {pathname: '/template'}}

	// if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
	// 	return (
	// 	  <Redirect to={from}/>
	// 	);
	// }

	return (
	  <div className="auth-page">
		  <Container className="col-12">
			  <Row className="d-flex align-items-center">
				  <Col xs={12} lg={6} className="left-column">
					  <Widget className="widget-auth widget-p-lg">
						  <div className="d-flex align-items-center justify-content-between py-3">
							  <p className="auth-header mb-0">회원가입</p>
						  </div>
						  <form onSubmit={(event => doRegister(event))}>
							  <FormGroup className="my-3">
								  <FormText>이름 입력</FormText>
								  <Row>
									  <Col xs={4} className="mx-0 pr-0">
										  <Input
											id="email"
											className="input-transparent pl-3"
											value={state.familyName}
											onChange={(event) => changeCred(event)}
											type="text"
											required
											name="familyName"
											placeholder="성"
										  />
									  </Col>
									  <Col className="mx-0">
										  <Input
											id="email"
											className="input-transparent pl-3"
											value={state.firstName}
											onChange={(event) => changeCred(event)}
											type="text"
											required
											name="firstName"
											placeholder="이름"
										  />
									  </Col>
								  </Row>
							  </FormGroup>
							  <FormGroup className="my-3">
								  <FormText>이메일</FormText>
								  <Input
									id="email"
									className="input-transparent pl-3"
									value={state.email}
									onChange={(event) => changeCred(event)}
									type="email"
									required
									name="email"
									placeholder="Email"
								  />
							  </FormGroup>
							  <FormGroup className="my-3">
								  <FormText>ID</FormText>
								  <Row>
									  <Col xs={7} className="mx-0 pr-0">
										  <Input
											id="username"
											className="input-transparent pl-3"
											value={state.username}
											onChange={(event) => changeCred(event)}
											type="text"
											required
											name="username"
											placeholder="ID"
										  />
									  </Col>
									  <Col>
										  <Button className="rounded-pill" onClick={overlapCheck}>
											  중복 확인
										  </Button>
									  </Col>
								  </Row>
							  </FormGroup>
							  <FormGroup className="my-3">
								  <div className="d-flex justify-content-between">
									  <FormText>Password</FormText>
								  </div>
								  <Input
									id="password"
									className="input-transparent pl-3"
									value={state.password}
									onChange={(event => changeCred(event))}
									type="password"
									required
									name="password"
									placeholder="Password"
								  />
							  </FormGroup>
							  <div className="bg-widget d-flex justify-content-center">
								  <Button className="rounded-pill my-3" type="submit"
										  color="secondary-red">
									  회원가입
								  </Button>
							  </div>
						  </form>
					  </Widget>
				  </Col>
				  <Col xs={0} lg={6} className="right-column">
					  <div>
						  <img src={loginImage} alt="Error page"/>
					  </div>
				  </Col>
			  </Row>
		  </Container>
		  <Footer/>
	  </div>
	)
}

Register.propTypes = {
	dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
	return {
		isFetching: state.auth.isFetching,
		isAuthenticated: state.auth.isAuthenticated,
		errorMessage: state.auth.errorMessage,
	};
}

export default withRouter(connect(mapStateToProps)(Register));
