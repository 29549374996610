import {
	GET_FETCH_PROJECT,
	GET_FETCH_PROJECT_DETAIL,
	GET_FETCH_PROJECT_LIST,
	STORE_SELECTED_PROJECT,
	GET_FETCH_USER,
	POST_FETCH_ASSIGN_PROJECT,
	CHECK_FETCH_RESULT,
	GET_PROJECT_TEXT,
	STORE_LAST_POSITION
} from "../actions/getFetchAction.js";

export default function getFetchReducer(state = {
	isFetching: false,
}, action) {
	switch (action.type) {
		case GET_FETCH_PROJECT:
			return Object.assign({}, state, {
				isFetching: true,
				projectData: action.payload
			});
		case GET_FETCH_PROJECT_DETAIL:
			return Object.assign({}, state, {
				isFetching: true,
				projectDetailData: action.payload
			});
		case GET_FETCH_PROJECT_LIST:
			return Object.assign({}, state, {
				isFetching: true,
				projectListData: action.payload
			});
		case STORE_SELECTED_PROJECT:
			return Object.assign({}, state, {
				selectedProject: action.payload
			});
		case GET_FETCH_USER:
			return Object.assign({}, state, {
				isFetching: true,
				userData: action.payload
			});
		case POST_FETCH_ASSIGN_PROJECT:
			return Object.assign({}, state, {
				isFetching: true,
				assignProjectData: action.payload
			});
		case CHECK_FETCH_RESULT:
			return Object.assign({}, state, {
				isFetching: true,
				checkFetchResultData: action.payload
			});
		case GET_PROJECT_TEXT:
			return Object.assign({}, state, {
				isFetching: true,
				projectTextData: action.payload
			});
		case STORE_LAST_POSITION:
			return Object.assign({}, state, {
				isFetching: true,
				lastPositionData: action.payload
			});
		default:
			return state;
	}
}

