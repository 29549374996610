import React, {useEffect, useRef, useState} from "react";
import ReactWaves, {Regions} from '@dschoon/react-waves';

import {Slider, SvgIcon, TextField} from "@mui/material";

import exaudio_local from "./audio/first.mp3";
import test_local from "./audio/test.wav";

import {
  Col,
  Row,
  Button, Progress, Card, CardTitle,
} from "reactstrap";

// Icons
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import {Text} from "recharts";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {VolumeUp} from "@material-ui/icons";

function WaveFunction(props) {
  const onDataChange = props.onTranscriptChange;
  const onMeaningDataChange = props.onMeaningTranscriptChange;
  const onTimeChange = props.onTimeChange;
  const audioId = props.jsonItem.audio_id;
  const regionStart = props.jsonItem.start;
  const regionEnd = props.jsonItem.end;
  const changedText = props.jsonItem.text;
  const changedMeaningText = props.jsonItem.meaning_text;
  const originalText = props.originalItem.originalText;
  const isExclude = props.jsonItem.exclude
  const transcriptValue = props.jsonItem.transcriptValue;
  const changeExclude = props.onExcludeChange;
  const changeNowAudio = props.onNowAudioChange
  const exaudio = props.audio;
  // const exaudio = props.audio.substring(6);
  const onChangeVolume = props.onVolumeChange;
  const audioVolume = props.audioVolume;

  // console.log("props: ", props.jsonItem)
  // console.log("changedMeaningText: ", changedMeaningText)


  const isMounted = useRef(false);


  const [state, setState] = useState({
	loaded: false,
	wavesurfer: null,
	playing: false,
	pos: 0,
	activeRegion: "One",
	active: false,
	regions: {
	  "One": {
		id: "One",
		start: regionStart,
		end: regionEnd,
		color: 'rgba(133,204,202,0.25)',
		drag: true,
		resize: true
	  }
	}
  });

  const [exclude, setExclude] = useState();

  const [transcript, setTranscript] = useState('')
  const [meaningTranscript, setMeaningTranscript] = useState('')
  const [prevWavesurfer, setPrevWavesurfer] = useState(null);

  useEffect(() => {
	// getAudioFromServer();
	if (isMounted.current) {
	  setTimeout(() => {
		setState({
		  loaded: false,
		  wavesurfer: null,
		  playing: false,
		  pos: 0,
		  activeRegion: "One",
		  active: false,
		})
	  }, 1);
	  setTimeout(() => {
		setState((prevState) => {
		  return {
			...prevState,
			pos: 0,
			wavesurfer: prevState.wavesurfer,
			regions: {
			  "One": {
				id: "One",
				start: regionStart,
				end: regionEnd,
				color: 'rgba(133,204,202,0.25)',
				drag: true,
				resize: true
			  }
			}
		  }
		})
	  }, 1);
	} else {
	  isMounted.current = true;
	}
  }, [exaudio]);

  useEffect(() => {
	if (transcriptValue) {
	  for (let i = 0; i < transcriptValue.length; i++) {
		if (transcriptValue[i].audio_id === audioId) {
		  setTranscript(transcriptValue[i].text);
		  setMeaningTranscript(transcriptValue[i].meaning_text);
		} else {
		  setTranscript('');
		  setMeaningTranscript('');
		}
	  }
	} else {
	  setTranscript('');
	  setMeaningTranscript('');
	}
  }, [audioId]);

  useEffect(() => {
	console.log("isExclude: ", isExclude)
	if (isExclude) {
	  setExclude(true)
	  changeExclude(true, audioId);
	} else {
	  setExclude(false)
	  changeExclude(false, audioId);
	}
  }, [audioId]);

  console.log("transcript: ", changedText)
  console.log("meaningTranscript: ", changedMeaningText)


  const onLoading = ({wavesurfer, originalArgs = []}) => {
	setState((prevState) => {
	  return {
		...prevState,
		loaded: originalArgs[0] === 100,
		wavesurfer: wavesurfer,
	  }
	});
  };

  const onPosChange = (pos, wavesurfer) => {
	if (pos !== state.pos) {
	  setState((prevState) => {
		return {
		  ...prevState,
		  pos: pos,
		  wavesurfer: wavesurfer
		}
	  });
	}
  };

  const secondsToPosition = (sec) => {
	return 1 / state.wavesurfer.getDuration() * sec;
  };

  const handleSingleRegionUpdate = (e) => {
	const newState = Object.assign(state, {
	  regions: {
		[e.region.id]: e.region
	  }
	});

	setState(newState);
	const newTime = {
	  id: "One",
	  start: e.region.start,
	  end: e.region.end,
	}
	onTimeChange(newTime);
  };

  const handleRegionClick = (e) => {
	setTimeout(() => {
	  state.wavesurfer.seekTo(secondsToPosition(e.originalArgs[0].start));
	}, 50);
  };

  const handleRegionDone = () => {
	setState((prevState) => {
	  return {
		...prevState,
		playing: null
	  }
	});
  };

  const updateTranscript = (e) => {
	const newTranscript = {
	  id: audioId,
	  text: e.target.value,
	}
	onDataChange(newTranscript);
  }

  const updateMeaningTranscript = (e) => {
	const newTranscript = {
	  id: audioId,
	  meaning_text: e.target.value,
	}
	onMeaningDataChange(newTranscript);
  }

  const changeVolume = (e) => {
	onChangeVolume(e.target.value);
  }


  return (
	<div>
	  <div className="mt-3 mb-1 mx-4">
		<p className="headline-2 mb-3">{props.jsonItem.file_name}</p>
	  </div>
	  <Col>
		<Row key={exaudio + audioVolume}>
		  <ReactWaves
			audioFile={exaudio}
			className={'custom-waves'}
			options={{
			  fillParent: true,
			  barGap: 2,
			  barWidth: 2,
			  barHeight: audioVolume,
			  barRadius: 3,
			  cursorWidth: 0,
			  height: 100,
			  hideScrollbar: false,
			  progressColor: '#20bec5',
			  responsive: false,
			  waveColor: '#D1D6DA',
			}}
			volume={audioVolume}
			zoom={1}
			pos={state.pos}
			playing={state.playing}
			onPosChange={onPosChange}
			onLoading={onLoading}
		  >
			<Regions
			  onSingleRegionUpdate={handleSingleRegionUpdate}
			  onSingleRegionIn={() => {
				console.log('single_in')
			  }}
			  onSingleRegionOut={() => {
				console.log('single_out')
			  }}
			  onSingleRegionRemove={() => {
				console.log('single_remove')
			  }}
			  onSingleRegionClick={() => {
			  }}
			  onSingleRegionOver={() => {
				console.log('single_over')
			  }}
			  onSingleRegionLeave={() => {
				console.log('single_leave')
			  }}
			  onRegionClick={handleRegionClick}
			  onRegionIn={() => {
				console.log('in')
			  }}
			  onRegionOut={handleRegionDone}
			  onRegionRemove={() => {
				console.log('remove')
			  }}
			  onRegionDblclick={() => {
				console.log('dblclick')
			  }}
			  onRegionOver={() => {
				console.log('over')
			  }}
			  onRegionLeave={() => {
				console.log('leave')
			  }}
			  regions={state.regions}
			/>
		  </ReactWaves>
		</Row>
		<Row className="mr-5 mt-2">
		  <Col className="mx-auto" xs={10}>
		  </Col>
		  <Col className="mx-auto text-right px-0" xs={1}>
			<SvgIcon component={VolumeUp}/>
		  </Col>
		  <Col className="mx-auto text-left" xs={1}>
			<Slider value={audioVolume} onChange={(e) => {
			  changeVolume(e)
			}}/>
		  </Col>
		</Row>
	  </Col>
	  <Row className="align-items-center ml-5">
		<Col className="mb-md-0 mx-0 ml-4 mr-4 text-center" xs={1.5}>
		  <Card body>
			<CardTitle>
			  <Typography fontWeight='bold' noWrap>
				화자 선택
			  </Typography>
			</CardTitle>
			<Box mb={1}>
			  <Button
				color={!exclude ? "success" : "secondary"}
				onClick={() => {
				  changeExclude(false, audioId);
				  setExclude(false);
				}}>

				<Typography fontWeight='bold' noWrap>
				  아동
				</Typography>
			  </Button>
			</Box>
			<Box>
			  <Button
				color={exclude ? "secondary-red" : "secondary"}
				onClick={() => {
				  changeExclude(true, audioId);
				  setExclude(true);
				}}>
				<Typography fontWeight='bold' noWrap>
				  성인
				</Typography>
			  </Button>
			</Box>
		  </Card>
		</Col>
		<Col className="mb-4 mb-md-0 ml-0" xs={10} key={exaudio}>
		  <Card body>
			<Typography fontWeight='bold' noWrap>
			  발화 내용 입력
			</Typography>
			<Typography variant="caption" fontWeight='bold' noWrap mt={2}>
			  AI 전사
			</Typography>
			<Row>
			  <TextField
				fullWidth
				disabled={true}
				defaultValue={originalText}
				id="standard-size-small"
				size="small"
				// value={transcript}
				sx={{ml: 1, mr: 1}}
			  />
			</Row>
			<Typography variant="caption" fontWeight='bold' noWrap mt={2}>
			  정답 전사
			</Typography>
			<Row>
			  <TextField
				fullWidth
				disabled={exclude}
				defaultValue={changedText}
				id="standard-size-small"
				size="small"
				// value={transcript}
				sx={{ml: 1, mr: 1}}
				onChange={
				  (e) => {
					updateTranscript(e);
					setTranscript(e.target.value);
				  }}
			  />
			</Row>
			<Typography variant="caption" fontWeight='bold' noWrap mt={2}>
			  의미 전사
			</Typography>
			<Row className="mb-4">
			  <TextField
				fullWidth
				disabled={exclude}
				defaultValue={changedMeaningText}
				id="standard-size-small"
				size="small"
				// value={meaningTranscript}
				sx={{ml: 1, mr: 1}}
				onChange={
				  (e) => {
					updateMeaningTranscript(e);
					setMeaningTranscript(e.target.value)
				  }}
			  />
			</Row>
		  </Card>
		</Col>
		<Row className="mx-auto mt-4 mb-1">
		  <Col className="mb-4 mb-xl-0 mx-1" xs={6} sm={6} xl={3}>
			<Button type={'button'}
					onClick={() => {
					  changeNowAudio('prev');
					}}
			>
			  <SvgIcon component={SkipPreviousIcon}/>
			</Button>
		  </Col>
		  <Col className="mb-4 mb-xl-0" xs={6} sm={6} xl={3}>
			<Button onClick={() => {
			  setState((prevState) => {
				return {
				  ...prevState,
				  playing: !prevState.playing
				}
			  });
			}}>
			  {!state.playing ? <SvgIcon component={PlayArrowIcon}/> : <SvgIcon component={PauseIcon}/>}
			</Button>
		  </Col>
		  <Col className="mb-4 mb-xl-0 mx-1" xs={6} sm={6} xl={3}>
			<Button type={'button'}
					onClick={() => {
					  setPrevWavesurfer(state.wavesurfer);
					  changeNowAudio('next');
					}}
			>
			  <SvgIcon component={SkipNextIcon}/>
			</Button>
		  </Col>
		</Row>
	  </Row>
	</div>
  )
	;
}

export default WaveFunction;
