import {logoutUser} from "../../actions/auth";

export default function PatchFetchData(fetchArgs) {
  const {fetchUrl, fetchMethod, fetchHeader, fetchBody, fetchAction, fetchDispatch, fetchResult} = fetchArgs;
  // console.log(fetchArgs)
  const fetchData = fetch("/api" + fetchUrl, {
	method: "PATCH",
	headers: fetchHeader,
	body: fetchBody
  })
	.then(response => {
	  if (!response.ok) {
		// console.log("response: ", response)
		window.location.href = "/#/login"
		throw new Error(response.statusText)
	  }
	  return response.json()

	}).catch(err => {
	  console.log("request result: ", err)
	}).then(data => {
	  console.log("request result: ", data)
	  fetchDispatch(fetchAction(data))
	})
}
