export const GET_FETCH_PROJECT = 'Get_Fetch_Project';
export const GET_FETCH_PROJECT_DETAIL = 'Get_Fetch_Project_Detail';
export const GET_FETCH_PROJECT_LIST = 'Get_Fetch_Project_List';
export const STORE_SELECTED_PROJECT = 'Store_Selected_Project';
export const GET_FETCH_USER = 'Get_Fetch_User';
export const POST_FETCH_ASSIGN_PROJECT = 'Post_Fetch_Assign_Project';
export const CHECK_FETCH_RESULT = 'Check_Fetch_Result';
export const GET_PROJECT_TEXT = 'Get_Project_Text';
export const STORE_LAST_POSITION = 'Store_Last_Position';

export function GetFetchProject(payload) {
	return {
		type: GET_FETCH_PROJECT,
		payload,
	};
}

export function GetFetchProjectDetail(payload) {
	return {
		type: GET_FETCH_PROJECT_DETAIL,
		payload,
	};
}

export function GetFetchProjectList(payload) {
	return {
		type: GET_FETCH_PROJECT_LIST,
		payload,
	};
}

export function StoreSelectedProject(payload) {
	return {
		type: STORE_SELECTED_PROJECT,
		payload,
	};
}

export function GetFetchUser(payload) {
	return {
		type: GET_FETCH_USER,
		payload,
	};
}

export function PostFetchAssignProject(payload) {
	return {
		type: POST_FETCH_ASSIGN_PROJECT,
		payload,
	};
}

export function CheckFetchResult(payload) {
	return {
		type: CHECK_FETCH_RESULT,
		payload,
	};
}

export function GetProjectText(payload) {
	return {
		type: GET_PROJECT_TEXT,
		payload,
	};
}

export function StoreLastPosition(payload) {
	return {
		type: STORE_LAST_POSITION,
		payload,
	};
}

