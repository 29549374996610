import { combineReducers } from "redux";
import navigation from "./navigation.js";
import register from "./register.js";
import auth from "./auth.js";
import getFetchReducer from "./getFetchReducer.js";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducers = combineReducers({
  register,
  auth,
  navigation,
  getFetchReducer
});

const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["getFetchReducer"]
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default persistedReducer;
