import React, {useState} from "react";
import {useEffect} from "react";
import GetFetchData from "../../components/Request/Request";
import {useDispatch, useSelector} from "react-redux";
import ProjectCard from "./components/ProjectCard";
import {
	CheckFetchResult,
	GET_FETCH_PROJECT_DETAIL,
	GetFetchProject,
	GetFetchProjectList
} from "../../actions/getFetchAction";
import {Box, CircularProgress, Grid} from "@mui/material";
import navigation from "../../reducers/navigation";


const Project = () => {
	const isMounted = React.useRef(false);
	const [fetchArgs, setFetchArgs] = useState({});
	const nowLocation = window.location.href;
	const navigation = useSelector(state => state.navigation);
	const projectSelector = useSelector(state => state.getFetchReducer);
	const dispatch = useDispatch();
	const history = navigation.history;
	const projectFetchArgs = {
		fetchUrl: "/project/list",
		fetchMethod: "GET",
		fetchHeader: {
			Authorization: "Token " + localStorage.getItem("token")
		},
		fetchAction: GetFetchProject,
		fetchDispatch: dispatch,
		fetchErrorAction: CheckFetchResult
	}
	const projectAllFetchArgs = {
		fetchUrl: "/project/list/all",
		fetchMethod: "GET",
		fetchHeader: {
			Authorization: "Token " + localStorage.getItem("token")
		},
		fetchAction: GetFetchProjectList,
		fetchDispatch: dispatch,
		fetchErrorAction: CheckFetchResult
	}
	useEffect(() => {
		// if (isMounted.current) {
		if (nowLocation.includes('project')) {
			GetFetchData(projectFetchArgs)
		} else if (nowLocation.includes('check-text')) {
			GetFetchData(projectAllFetchArgs)
		}
		// } else {
		// 	isMounted.current = true;
		// }
	}, []);
	useEffect(() => {
		if (isMounted.current) {
			if (projectSelector.checkFetchResultData) {
				if (projectSelector.checkFetchResultData === "failed") {
					history.push("/login");
				}
			}
		} else {
			isMounted.current = true;
		}
	}, [projectSelector.checkFetchResultData]);

	if (nowLocation.includes('project')) {
		if (projectSelector.projectData) {
			if (projectSelector.projectData.message.length === 0) {
				return (
				  <div>
					  <h1>No projects found</h1>
				  </div>
				)
			} else {
				return (
				  <ProjectCard projectProperties={projectSelector.projectData.message} dispatch={dispatch}/>
				)
			}
		} else {
			return (
			  <Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				sx={{minHeight: '60vh'}}
			  >
				  <Grid item xs={3}>
					  <CircularProgress/>
				  </Grid>
			  </Grid>
			)
		}
	} else {
		if (projectSelector.projectListData) {
			if(projectSelector.projectListData.message) {
				return (
				  <ProjectCard projectProperties={projectSelector.projectListData.message} dispatch={dispatch}/>
				)
			} else {
				return (
				  <div>
					  <h1>No projects found</h1>
				  </div>
				)
			}
		} else {
			return (
			  <Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				sx={{minHeight: '60vh'}}
			  >
				  <Grid item xs={3}>
					  <CircularProgress/>
				  </Grid>
			  </Grid>
			)
		}
	}
}

export default Project
