import {Col, Progress, Row} from "reactstrap";
import Widget from "../../../components/Widget/Widget";
import s from "../../dashboard/Dashboard.module.scss";
import paper from "./document.svg";
import React from "react";
import {StoreLastPosition, StoreSelectedProject} from "../../../actions/getFetchAction";

const ProjectCard = (projectProperties) => {
  const nowLocation = window.location.href;
  const dispatch = projectProperties.dispatch;
  const checkZeroLastPosition = (last_position) => {
	if (!last_position) {
	  return 0
	} else {
	  return last_position
	}
  }
  // console.log("projectProperties: ", projectProperties)
  const selectProject = (project) => {
	dispatch(StoreSelectedProject(project.project))
	dispatch(StoreLastPosition(project.last_position))
	window.location.href = '#/wave/waveform'
  }
  const selectCheckText = (project_name) => {
	dispatch(StoreSelectedProject(project_name))
	window.location.href = '#/wave/check-text-detail'
  }
  console.log("projectProperties: ", projectProperties)
  return (
	<Col>
	  <Row className="mb-4">
		{projectProperties.projectProperties.map((project, index) => (
		  <Col className="mb-4 mb-xl-0" xs={6} sm={6} xl={4}
			   key={index}>
			<div className="mb-4">
			  <Widget className="widget-p-sm mb"
					  onClick={() => {
						nowLocation.includes('project') ?
						  selectProject(project)
						  :
						  selectCheckText(project.project_name)
					  }}
					  style={{cursor: "pointer"}}
			  >
				<div className={s.smallWidget}>
				  <div className="d-flex mb-4">
					<img className="py-1 mr-2 img-fluid" src={paper} alt="..."/>
					<div className="d-flex flex-column">
					  <p className="headline-3">
						{nowLocation.includes('project') ?
						  project.project
						  :
						  project.project_name
						}
					  </p>
					  {nowLocation.includes('project') ?
						<p className="body-2">{
						  checkZeroLastPosition(project.last_position) - 1
						}<span className="body-3 muted">/ {Number(project.file_count) - 2}</span></p>
						:
						null
					  }
					</div>
				  </div>
				  {nowLocation.includes('project') ?
					<div>
					  <Progress color="success" className={`progress-xs ${s.mutedTeal}`}
								value={100 * checkZeroLastPosition(project.last_position) / (Number(project.file_count) - 2)}/>
					</div>
					:
					null
				  }
				</div>
			  </Widget>
			</div>
		  </Col>
		))}
	  </Row>
	</Col>
  );
}

export default ProjectCard;
