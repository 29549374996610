export function calcCER(reference, transcription, withPunctuation, withCapitalization) {
  console.log("calculating")
  const distance = levenshteinDistance(reference, transcription, withPunctuation, withCapitalization);
  return distance / reference.length;
}

function levenshteinDistance(reference, transcription, withPunctuation, withCapitalization) {
  if (!reference.length) return transcription.length;
  if (!transcription.length) return reference.length;

  // delete all probably not recognized special symbols, because no transcription service can transcribe such symbols
  reference = reference.replace(/[\/#\^&\*{}=\-_`~()"']/g, "");
  transcription = transcription.replace(/[\/#\^&\*{}=\-_`~()"']/g, "");

  if (!withPunctuation) {
	// delete all punctuation symbols like ?,.-;!
	reference = reference.replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, "");
	transcription = transcription.replace(/[.,\/#!?$%\^&\*;:{}=\-_`~()]/g, "");
  }
  if (!withCapitalization) {
	reference = reference.toLowerCase();
	transcription = transcription.toLowerCase();
  }
  // swap to save some memory O(min(a,b)) instead of O(a)
  if (reference.length > transcription.length) {
	var tmp = reference;
	reference = transcription;
	transcription = tmp;
  }

  var row = [];
  // init the row
  for (var i = 0; i <= reference.length; i++) {
	row[i] = i;
  }
  // fill in the rest
  for (var i = 1; i <= transcription.length; i++) {
	var prev = i;
	for (var j = 1; j <= reference.length; j++) {
	  var val;
	  if (transcription.charAt(i - 1) == reference.charAt(j - 1)) {
		val = row[j - 1]; // match
	  } else {
		val = Math.min(row[j - 1] + 1, // substitution
		  prev + 1,     // insertion
		  row[j] + 1);  // deletion
	  }
	  row[j - 1] = prev;
	  prev = val;
	}
	row[reference.length] = prev;
  }

  return row[reference.length];
}
