import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withRouter, Redirect, Link} from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget";
import Footer from "../../components/Footer/Footer";
import {loginUser} from "../../actions/auth";
import hasToken from "../../services/authService";


import loginImage from "../../assets/loginImage.svg";
import SofiaLogo from "../../components/Icons/SofiaLogo.js";
import GoogleIcon from "../../components/Icons/AuthIcons/GoogleIcon.js";
import TwitterIcon from "../../components/Icons/AuthIcons/TwitterIcon.js";
import FacebookIcon from "../../components/Icons/AuthIcons/FacebookIcon.js";
import GithubIcon from "../../components/Icons/AuthIcons/GithubIcon.js";
import LinkedinIcon from "../../components/Icons/AuthIcons/LinkedinIcon.js";

import {CheckFetchResult} from "../../actions/getFetchAction";
import PostFetchData from "../../components/Request/Post";


const Login = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
	username: '',
	password: '',
  })
  const [loginResult, setLoginResult] = useState("");
  const isMounted = React.useRef(false);

  const loginSelector = useSelector(state => state.getFetchReducer);

  useEffect(() => {
	if (isMounted.current) {
	  if (loginSelector.checkFetchResultData) {
		setLoginResult(loginSelector.checkFetchResultData.result)
	  } else {
		return;
	  }
	} else {
	  isMounted.current = true;
	}

  }, [loginSelector]);

  useEffect(() => {
	if (loginResult === "success") {
	  console.log("loginSelector", loginSelector.checkFetchResultData.data.Token)
	  const token = loginSelector.checkFetchResultData.data.Token;
	  localStorage.setItem("authenticated", true);
	  localStorage.setItem("token", token);
	  props.history.push("/wave");
	} else if (loginResult === "fail") {
	  alert("아이디 또는 비밀번호가 일치하지 않습니다.")
	  setLoginResult("")
	}
  }, [loginResult]);

  const doLogin = (e) => {
	e.preventDefault();
	const fatchArgs = {
	  fetchUrl: "/auth/signin",
	  fetchMethod: "POST",
	  fetchHeader: {
		"Content-Type": "application/json",
	  },
	  fetchBody: JSON.stringify({
		username: state.username,
		password: state.password,
	  }),
	  fetchAction: CheckFetchResult,
	  fetchDispatch: dispatch
	}
	PostFetchData(fatchArgs);
  }

  const changeCreds = (event) => {
	console.log(event.target.name)
	setState({...state, [event.target.name]: event.target.value})
  }

  const {from} = props.location.state || {from: {pathname: '/wave'}};
  if (hasToken(JSON.parse(localStorage.getItem('authenticated')))) {
	return (
	  <Redirect to={from}/>
	)
  }

  return (
	<div className="auth-page">
	  <Container className="col-12">
		<Row className="d-flex align-items-center">
		  <Col xs={12} lg={6} className="left-column">
			<Widget className="widget-auth widget-p-lg">
			  <div className="d-flex align-items-center justify-content-between py-3">
				<p className="auth-header mb-0">로그인</p>
			  </div>
			  <form onSubmit={(event) => doLogin(event)}>
				<FormGroup className="my-3">
				  <FormText>ID</FormText>
				  <Input
					id="username"
					className="input-transparent pl-3"
					value={state.username}
					onChange={(event) => changeCreds(event)}
					type="text"
					required
					name="username"
					placeholder="ID"
				  />
				</FormGroup>
				<FormGroup className="my-3">
				  <div className="d-flex justify-content-between">
					<FormText>Password</FormText>
					{/*<Link to="/error">Forgot password?</Link>*/}
				  </div>
				  <Input
					id="password"
					className="input-transparent pl-3"
					value={state.password}
					onChange={(event) => changeCreds(event)}
					type="password"
					required
					name="password"
					placeholder="Password"
				  />
				</FormGroup>
				<div className="bg-widget d-flex justify-content-center">
				  <Button className="rounded-pill my-3" type="submit" color="secondary-red">로그인</Button>
				</div>

				<Link to="/register">회원가입 하러 가기</Link>
			  </form>
			</Widget>
		  </Col>
		  <Col xs={0} lg={6} className="right-column">
			<div>
			  <img src={loginImage} alt="Error page"/>
			</div>
		  </Col>
		</Row>
	  </Container>
	  <Footer/>
	</div>
  )
}


Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
	isFetching: state.auth.isFetching,
	isAuthenticated: state.auth.isAuthenticated,
	errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
