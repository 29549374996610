import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Badge, Button,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import moreIcon from "../../assets/tables/moreIcon.svg";

import s from "../tables/Tables.module.scss";
import mock from "../tables/mock.js";
import {
  CheckFetchResult,
  GetFetchProject,
  GetFetchProjectDetail,
  GetFetchProjectList,
  GetFetchUser, GetProjectText
} from "../../actions/getFetchAction";
import GetFetchData from "../../components/Request/Request";
import {useDispatch, useSelector} from "react-redux";
import PostFetchData from "../../components/Request/Post";
import {calcCER} from "../../components/calcCER";


const TextCheck = () => { //TODO 새로운 API 적용
  const SERVER_URL = "https://dev-vst.vision21tech.com"
  const audioSelector = useSelector(state => state.getFetchReducer);
  const [secondTable] = useState(mock.secondTable);
  const [secondTableCurrentPage, setSecondTableCurrentPage] = useState(0);
  const [tableDropdownOpen, setTableMenuOpen] = useState(false);
  const isMounted = React.useRef(false);

  const pageSize = 10;
  const secondTablePagesCount = Math.ceil(secondTable.length / pageSize);

  const setSecondTablePage = (e, index) => {
	e.preventDefault();
	setSecondTableCurrentPage(index);
  }

  const tableMenuOpen = () => {
	setTableMenuOpen(!tableDropdownOpen);
  }

  const handleDownload = (projeceName) => {
	window.open(SERVER_URL + "/api/project/download/csv?project_name=" + projeceName)
	window.open(SERVER_URL + "/api/project/download/audio?project_name=" + projeceName)
  }

  const projectSelector = useSelector(state => state.getFetchReducer);
  const dispatch = useDispatch();
  const fetchProjectArgs = {
	fetchUrl: "/project/list/text?project_name=" + projectSelector.selectedProject,
	fetchMethod: "GET",
	fetchHeader: {
	  Authorization: "Token " + localStorage.getItem("token")
	},
	fetchAction: GetProjectText,
	fetchDispatch: dispatch
  };

  useEffect(() => {
	GetFetchData(fetchProjectArgs);
	// GetFetchData(fetchUserArgs);
  }, []);

  if (projectSelector.projectTextData && projectSelector.selectedProject) {
	if (projectSelector.projectTextData.message[0]) {
	  console.log("projectSelector.projectTextData: ", projectSelector.projectTextData)
	  return (
		<div>
		  <Row>
			<Col>
			  <Row className="mb-4">
				<Col>
				  <Widget>
					<div className={s.tableTitle}>
					  <Row className="mx-auto vw-100 justify-content-between">
						<Col>
						  <div
							className="headline-2 float-left">{projectSelector.selectedProject}</div>
						</Col>
						<Col>
						  <Button className="float-right"
								  onClick={() => handleDownload(projectSelector.selectedProject)}>
							다운로드
						  </Button>
						</Col>
					  </Row>
					</div>
					<div className="widget-table-overflow">
					  <Table className="table-striped table-borderless table-hover" responsive>
						<thead>
						<tr>
						  <th>
							<div className="checkbox checkbox-primary">
							</div>
						  </th>
						  <th className="col-md-2">파일 ID</th>
						  <th className="col-md-3">원본 텍스트</th>
						  <th className="col-md-3">정답 전사</th>
						  <th className="col-md-3">의미 전사</th>
						  <th className="col-md-1">CER</th>
						</tr>
						</thead>
						<tbody>
						{projectSelector.projectTextData.message[0].sentence
						  .map(item => (
							!item.is_adult ?
							  <tr key={uuidv4()}>
								<td>
								  <div className="checkbox checkbox-primary">
								  </div>
								</td>
								<td>{item.file_name}</td>
								<td>{item.sentences}</td>
								<td>{item.new_sentences}</td>
								<td>{item.new_meaning_sentences}</td>
								<td>{calcCER(item.sentences, item.new_sentences)}</td>
							  </tr>
							  : null
						  ))}
						</tbody>
					  </Table>
					</div>
				  </Widget>
				</Col>
			  </Row>
			</Col>
		  </Row>
		</div>
	  )
	} else {
	  return (
		<div>작업되지 않은 프로젝트입니다</div>
	  )
	}
  }
}

export default TextCheck;
